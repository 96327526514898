import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import '../../vendor/wire-elements/pro/resources/js/overlay-component.js';
import flatpickr from 'flatpickr';
import '@nextapps-be/livewire-sortablejs';
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import '@github/relative-time-element';

Sentry.init({
    dsn: window.sentry_dsn,
    release: window.sentry_release,
    environment: window.sentry_environment,
    integrations: [new BrowserTracing()],
    tracesSampleRate: window.sentry_traces_sample_rate,
});

window.Alpine = Alpine;
window.flatpickr = flatpickr;

Alpine.plugin(focus);

window.getFlatpickrDateFormat = (locale, withTime = false) => {
    let format = withTime ? {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    } : {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }

    const parts = new Intl.DateTimeFormat(locale, format).formatToParts(new Date());
    const hasDayPeriod = parts.find(object => object.type === 'dayPeriod') !== undefined;

    return parts.map(({type, value}) => {
        switch (type) {
            case "day": return "d";
            case "month": return "m";
            case "year": return "Y";
            case "hour": return hasDayPeriod ? "G" : "H";
            case "minute": return "i";
            case "dayPeriod": return "K";
            default: return value;
        }
    }).join('');
}

window.dateToIsoString = (date) => {
    let timezoneOffset = -date.getTimezoneOffset();
    let pad = (num) => (num < 10 ? '0' : '') + num;

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        (timezoneOffset>= 0 ? '+' : '-') + pad(Math.floor(Math.abs(timezoneOffset) / 60)) +
        ':' + pad(Math.abs(timezoneOffset) % 60);
}

Alpine.start();
